import { useEmailTemplateService } from "~/src/services/EmailTemplateService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetEmailTemplateQuery } = useEmailTemplateService();
  const result = await useGetEmailTemplateQuery(String(to.params.id));

  if (to.params.id === "new") return true;
  else if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Email Template not found",
      })
    );
});
